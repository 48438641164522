import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Avatar, Divider, Paper, Tooltip } from "@mui/material";
import BasicMap from "../../../pages/map/BasicMap";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { blue } from "@mui/material/colors";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import moment from "moment";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import PlaceIcon from "@mui/icons-material/Place";
import { ReactComponent as IPIcon } from "../../../vendor/ipIcon.svg";
import { useTranslation } from "react-i18next";
import image from "../images/Location.svg";
export const style = {
  display: "flex",
  width: "49%",
  bgcolor: "background.paper",
  height: "450px",
  boxShadow: 24,
  marginRight: 2,
  marginBottom: 1,
};

export default function CameraInfo({ data }) {
  const { t } = useTranslation();

  const center = [1.13822189091985, 3.94042968750001];
  // const center = [41.13822189091985, 63.94042968750001];
  const [pos, setPos] = React.useState(null);
  const [latLng, setLatLng] = React.useState(pos?.join(", "));
  const latLngRegExp =
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?), \s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
  React.useEffect(() => {
    if (pos) setLatLng(pos?.join(","));
    else setLatLng("");
  }, [pos]);
  React.useEffect(() => {
    if (latLngRegExp.test(latLng)) setPos(latLng?.split(","));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLng]);

  React.useEffect(() => {
    setPos(
      data?.computer?.location
        ? [data?.computer?.location.lat, data?.computer?.location.lng]
        : center
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div style={{ display: "flex" }}>
      <Paper sx={style}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography
            variant="h4"
            fontWeight={900}
            p={2}
            pt={4}
            align="center"
            sx={{ mt: 2, pb: 3 }}
          >
            {data?.code}
          </Typography>
          <Divider />
          <List sx={{ pt: 2, width: "100%", height: "300px" }}>
            <ListItemButton divider sx={{ height: "75px" }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PhotoCameraFrontIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={data?.title} secondary={t("Name")} />
            </ListItemButton>

            <ListItemButton divider sx={{ height: "75px" }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <IPIcon
                    style={{
                      fill: " #1d89f5",
                      stroke: "transparent",
                    }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={data?.ip_address}
                secondary={t("IpAddress")}
              />
            </ListItemButton>
            <ListItemButton divider sx={{ height: "75px" }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <RunningWithErrorsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  data?.args?.certificate_expired &&
                  moment(data?.args?.certificate_expired).format("DD-MM-YYYY  ")
                }
                secondary={t("Expiration Date")}
              />
            </ListItemButton>
            <ListItemButton divider sx={{ height: "75px" }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <GpsFixedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  data?.computer?.location ? (
                    <Typography>
                      {data?.computer?.location?.lat} ,
                      {data?.computer?.location?.lng}
                    </Typography>
                  ) : (
                    ""
                  )
                }
                secondary={t("Location")}
              />
            </ListItemButton>
            <ListItemButton sx={{ height: "75px" }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PlaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Tooltip
                    title={
                      data?.computer?.dislocation
                        ? data?.computer?.dislocation
                        : ""
                    }
                    placement={"top"}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {" "}
                      {data?.computer?.dislocation}
                    </Typography>
                  </Tooltip>
                }
                secondary={t("Dislocation")}
              />
            </ListItemButton>
          </List>
        </Box>
      </Paper>
      <Paper style={style}>
        <Box
          sx={{
            width: "100%",
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data?.computer?.location ? (
            <BasicMap pos={pos} />
          ) : (
            <img src={image} alt="Location" />
          )}
        </Box>
      </Paper>
    </div>
  );
}
