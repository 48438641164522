import React from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import { formatter } from "../../utils/functions";

function NavbarPoints() {
  const points = useSelector((state) => state.points.points);

  return (
    <Tooltip title={points}>
      <Button variant="text" color="inherit">
        <Typography sx={{ fontSize: "16px", pr: 1 }}>
          {formatter.format(points)}
        </Typography>
        <StarIcon color="warning" fontSize="large" />
      </Button>
    </Tooltip>
  );
}
export default NavbarPoints;
