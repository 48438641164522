import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import moment from "moment";
import axios from "./axios";

const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const currentTime = moment().toISOString();
  const decoded = jwtDecode(token);
  const timeLeft = moment(moment.unix(decoded.exp).toISOString()).diff(
    currentTime,
    "seconds"
  );
  if (timeLeft > 0) {
    return decoded;
  } else {
    localStorage.clear();
    return false;
  }
};

// const handleTokenExpired = (exp) => {
//   let expiredTimer;
//   window.clearTimeout(expiredTimer);
//   const currentTime = moment().toISOString();
//   const timeLeft = moment(moment.unix(exp).toISOString()).diff(
//     currentTime,
//     "seconds"
//   );
//   expiredTimer = window.setTimeout(() => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user");
//     delete axios.defaults.headers.common.Authorization;
//   }, timeLeft);
// };

const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // This function below will handle when token is expired
    const user = jwtDecode(token);
    localStorage.setItem("user", JSON.stringify(user));
    // handleTokenExpired(user.exp);
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common.Authorization;
  }
};
export { verify, sign, isValidToken, setSession };
