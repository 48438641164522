import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper as MuiPaper,
  Divider,
  TableHead,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { spacing } from "@mui/system";
import Loader from "../../../../../components/Loader";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import AppsApi from "../api";
import SignupForm from "./Fileuploud";
import useKeypress from "react-use-keypress";
import useWindowSize from "../../../../../components/size/useWindowSize";

const Paper = styled(MuiPaper)(spacing);
export const Container = styled(TableContainer)(
  ({ theme }) => `
  background-color: ${theme.palette.background.paper};
  max-height: calc(100vh - 370px);
  min-height: calc(100vh - 268px);
  overflow: auto;
  scrollbarWidth: thin;
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: ${theme.palette.background.paper};
  }

  ::-webkit-scrollbar-thumb {
    background: #555;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }`
);
export default function VersionTable({ name, dialog, setDialog, isEditing }) {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");
  const { enqueueSnackbar } = useSnackbar();
  const [appRows, setAppRows] = useState([]);
  const [width, height] = useWindowSize();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInput] = React.useState("");
  const [filteredResults] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [deviceData, setDeviceData] = useState({
    loading: false,
    data: null,
  });
  const footerInfo = (count, length) =>
    t("paginationFooter", {
      num1: new Intl.NumberFormat("ru-RU").format(count || 0),
      num: length || 0,
    });
  const fetchUsersData = async () => {
    setDeviceData({ ...deviceData, loading: true });
    try {
      const { data } = await AppsApi.getApp(name);
      setDeviceData({ data: data.data, loading: false });
    } catch (err) {
      enqueueSnackbar(err.message.en ? err.message.en : err.message, {
        variant: "error",
      });
      setDeviceData({ data: null, loading: false });
    }
  };
  useEffect(() => {
    fetchUsersData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useKeypress(["ArrowRight", "ArrowLeft"], (event) => {
    if (
      event.key === "ArrowRight" &&
      Math.ceil(
        (searchInput.length > 0
          ? filteredResults.length
          : deviceData?.data?.length || 0) /
          rowsPerPage -
          1
      ) !== page
    ) {
      setPage(page + 1);
    }
    if (event.key === "ArrowLeft" && page > 0) {
      setPage(page - 1);
    }
  });
  const user = localStorage.getItem("user");

  const emptyRows =
    rowsPerPage -
    Math.min(
      (searchInput.length > 0
        ? filteredResults.length
        : deviceData?.data?.length || 0) -
        page * rowsPerPage
    );

  useEffect(() => {
    const row = Math.floor((height - 415) / 52);
    setRowsPerPage(row);
  }, [height, width]);

  useEffect(() => {
    if (emptyRows === rowsPerPage && page > 0) setPage(page - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData?.data]);
  return (
    <>
      {deviceData.loading && deviceData.loading ? (
        <Loader size={200} />
      ) : (
        <Paper>
          {/* <TabContext value={value}> */}

          {/* <TabPanel style={{ padding: 0 }} value="1">{" "} */}
          <Container>
            {/* <AppTableTool setSearchInput={setSearchInput} setFocused={setFocused} /> */}
            <Table
              stickyHeader
              aria-labelledby="deviceTable"
              size={"medium"}
              aria-label="Device Table"
            >
              <TableHead>
                <TableRow hover>
                  <TableCell align="left">{t("Version")}</TableCell>
                  <TableCell align="left">{t("Created Date")}</TableCell>
                  <TableCell align="left">{t("Accessed date")}</TableCell>
                  <TableCell padding="none" align="right">
                    <Box mr={2}>{t("Actions")}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceData?.data?.map((row, index) => {
                  return (
                    <TableRow hover key={`${row.version}`}>
                      <TableCell align="left">{`v${row.version}`}</TableCell>
                      <TableCell align="left">
                        {format(new Date(row.modified_date), "dd.MM.yyyy")}
                      </TableCell>
                      <TableCell align="left">
                        {format(
                          new Date(row?.accessed_date),
                          "dd.MM.yyyy HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <IconButton
                            aria-label="details"
                            size="large"
                            disabled
                            // onClick={() =>
                            //   handleDownload(row.version, row.version)
                            // }
                          >
                            <Tooltip title={t("Delete")}>
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Container>
          <Divider my={2} />
          {user && (
            // <TablePagination
            //   rowsPerPageOptions={[rowsPerPage]}
            //   component="div"
            //   count={deviceData?.data?.length || 5}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            // />
            <Paper
              sx={{
                width: "100%",
                height: "50px",
                display: "flex",
                justifyContent: "end",
                p: 2,
                alignItems: "center",
              }}
            >
              {" "}
              <Typography ml={3}>
                {footerInfo(deviceData?.data?.length, deviceData?.data?.length)}
              </Typography>
            </Paper>
          )}
          {/* </TabPanel> */}
          {/* </TabContext> */}
          {user ? (
            <SignupForm
              fetchUser={fetchUsersData}
              appData={setDeviceData}
              name={name}
              tab={value}
              setOpen={setDialog}
              edit={isEditing}
              rows={appRows}
              setRows={setAppRows}
              handleChange={handleChange}
              open={dialog}
            />
          ) : (
            ""
          )}
        </Paper>
      )}
    </>
  );
}
