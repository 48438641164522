import axiosInstance from "../../../../utils/axios";

const getApp = (name) => axiosInstance.get(`/app/${name}`);
const createApp = ({ name, fromData }) =>
  axiosInstance.post(`/app/${name}`, fromData, {
    headers: { "Content-Type": "application/x-msdownload" },
  });
const updateApp = (data) => axiosInstance.put(`/app`, data);
const deleteApp = (data) => axiosInstance.delete(`/app`, { data });
const VersionApi = {
  getApp,
  createApp,
  updateApp,
  deleteApp,
};

export default VersionApi;
