import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import jwt_decode from "jwt-decode";

import { Button, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import axiosInstance from "../../utils/axios";

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const getLangEndpoint = "/users/lang";
  const getLanguage = () => axiosInstance.get(getLangEndpoint);

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required(t("Login is required")),
        password: Yup.string().max(255).required(t("Password is required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.username, values.password);
          const accessToken = window.localStorage.getItem("token");
          const decoded = jwt_decode(accessToken);
          const { data } = await getLanguage();
          localStorage.setItem("lang", data.data.lang ? data.data.lang : "cr");
          enqueueSnackbar(`${t("Welcome")} ${decoded?.user?.username}`, {
            variant: "success",
          });
          decoded?.user?.group_id === -3
            ? navigate("/dashboard")
            : decoded?.user?.group_id === -5
            ? navigate("/devices")
            : decoded?.user?.group_id === -4
            ? navigate("/dashboard")
            : decoded?.user?.group_id === -2
            ? navigate("/control")
            : decoded?.user?.group_id === -1
            ? navigate("/verification")
            : decoded?.user?.group_id > 0
            ? navigate("/map")
            : decoded?.user?.group_id === 0
            ? navigate("/dashboard")
            : navigate("/dashboard");
        } catch (error) {
          const message = error.message.en || t("Something went wrong");
          enqueueSnackbar(message, { variant: "error" });
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="text"
            name="username"
            label={t("Login")}
            value={values.username}
            variant="standard"
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label={t("Password")}
            value={values.password}
            variant="standard"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          >
            {t("sign in")}
          </Button>
          <Button component={Link} to="/sign-up" fullWidth color="primary">
            {t("Sign up")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
