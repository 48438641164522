import * as React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarThemeChange from "./NavbarThemeChange";
import { ReactComponent as Logo } from "../../vendor/logonav.svg";
import NavbarQR from "./NavbarQR";
import axios from "axios";
import NavbarPoints from "./NavbarPoints";
import { useDispatch } from "react-redux";
import { setPoint, setRank } from "../../redux/slices/points";
import NavbarRank from "./NavbarRank";
import IPPinger from "./IPPinger";
import { CommandsForAdmin } from "./CommandsForAdmin";
import { admin, isIpAddressOrDomain } from "../../utils/functions";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const WhiteLogo = styled(Logo)`
  margin-left: ${(props) => props.theme.spacing(3)};
`;

const Navbar = ({ onDrawerToggle }) => {
  const info = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setId] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const getTelegramToken = () =>
    axios.get(`${isIpAddressOrDomain()}/config/hash`);

  const getNotification = () =>
    axios.get(
      `${isIpAddressOrDomain()}/config/notification?limit=${10}&page=${1}&private=${true}`
    );
  const getNotificationRead = () =>
    axios.get(
      `${isIpAddressOrDomain()}/config/notification?limit=${10}&page=${1}&private=${true}&is_read=false`
    );
  const [hashData, setHashData] = React.useState({
    data: null,
    loading: false,
  });
  const [notificationData, setNotificationData] = React.useState({
    loading: true,
    data: null,
  });
  const [notificationDataCount, setNotificationDataCount] = React.useState({
    loading: true,
    count: null,
    data: [],
  });

  const fetchNotificationData = async () => {
    setNotificationData({ ...notificationData, loading: true });
    try {
      const { data } = await getNotification();

      setNotificationData({
        data: data.data,
        loading: false,
      });
    } catch (err) {
      setNotificationData({ data: [], loading: false });
    }
  };
  const fetchNotificationDataCount = async () => {
    setNotificationData({ ...notificationData, loading: true });
    try {
      const { data } = await getNotificationRead();
      setNotificationDataCount({
        count: data.count,
        data: data.data,
        loading: false,
      });
    } catch (err) {
      setNotificationDataCount({ data: [], count: null, loading: false });
    }
  };

  React.useEffect(() => {
    if (token) {
      fetchNotificationData();
      fetchNotificationDataCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTlgrm = async () => {
    setHashData({ ...hashData, loading: true });
    try {
      const { data } = await getTelegramToken();
      setHashData({ data: data.data, loading: false });
    } catch (err) {
      if (err.response.data.code === 401) {
        navigate("/sign-in");
        localStorage.clear();
      }
      enqueueSnackbar(err.response.data.message.en, {
        variant: "error",
      });

      setHashData({ data: null, loading: false });
    }
  };

  React.useEffect(() => {
    hashData.data === null && getTlgrm();
    if (hashData.data) {
      dispatch(setPoint(hashData?.data?.points));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (hashData.data) {
      dispatch(setPoint(hashData?.data?.points));
      dispatch(setRank(hashData?.data?.rank));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashData.data]);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {(info?.user?.group_id === -1 || info?.user?.group_id === -6) && (
                <Box>
                  <WhiteLogo />
                </Box>
              )}
            </Grid>
            <Grid item xs />
            {info?.user?.group_id === -1 && <NavbarPoints />}
            {info?.user?.group_id === -1 && <NavbarRank />}
            <Grid item>
              {admin() && <CommandsForAdmin />}
              {info?.user?.group_id !== -1 && <IPPinger />}
              {(info?.user?.group_id === 0 || info?.user?.group_id > 0) &&
                !hashData.loading &&
                hashData.data && <NavbarQR tlgrm={hashData?.data?.hash} />}
              <NavbarThemeChange />
              {info?.user?.group_id >= 0 && (
                <NavbarNotificationsDropdown
                  notificationData={notificationDataCount?.data}
                  setId={setId}
                  count={notificationDataCount?.count}
                  fetchNotificationDataCount={fetchNotificationDataCount}
                />
              )}
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
