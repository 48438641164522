import * as yup from "yup";

// Staff Route Validations
export const validationSchemaAdmin = (t) =>
  yup.object({
    description: yup
      .string()
      .max(100, t("maximum", { num: 100 }))
      .required(t("Required")),
    phoneNumber: yup
      .string()
      .max(17, t("maximum", { num: 17 }))
      .required(t("Required")),
    status: yup.boolean(),
  });

export const validationSchemaOperator = (selected, t, isEditing) =>
  selected
    ? yup.object({
        description: yup
          .string()
          .max(100, t("maximum", { num: 100 }))
          .required(t("Required")),
        phoneNumber: yup
          .string()
          .max(17, t("maximum", { num: 17 }))
          .required(t("Required")),
        status: yup.boolean(),
      })
    : yup.object({
        username: yup
          .string()
          .min(5, t("min", { num: 5 }))
          .max(100, t("maximum", { num: 100 }))
          .required(t("Required")),
        password: isEditing
          ? yup
              .string()
              .min(3, t("min", { num: 3 }))
              .max(100, t("maximum", { num: 100 }))
          : yup
              .string()
              .min(3, t("min", { num: 3 }))
              .max(100, t("maximum", { num: 100 }))
              .required(t("Required")),
        fullName: yup
          .string()
          .min(3, t("min", { num: 3 }))
          .max(100, t("maximum", { num: 100 }))
          .required(t("Required")),
        description: yup
          .string()
          .min(3, t("min", { num: 3 }))
          .max(100, t("maximum", { num: 100 }))
          .required(t("Required")),
        phoneNumber: yup
          .string()
          .max(17, t("maximum", { num: 17 }))
          .required(t("Required")),
        status: yup.boolean(),
      });

export const schemaFromTo = (t) =>
  yup.object().shape({
    begin: yup.date().required(t("Required")),
    end: yup.date().required(t("Required")),
  });

const octet = "(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)";
const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);

export const validationSchemaCamera = (t) =>
  yup.object({
    title: yup
      .string()
      .max(100, t("maximum", { num: 100 }))
      .required(t("Required")),
    code: yup
      .string()
      .max(255, t("maximum", { num: 255 }))
      .required(t("Required")),
    type: yup
      .string()
      .max(255, t("maximum", { num: 255 }))
      .required(t("Required")),
    username: yup
      .string()
      .max(255, t("maximum", { num: 255 }))
      .required(t("Required")),
    password: yup
      .string()
      .max(255, t("maximum", { num: 255 }))
      .required(t("Required")),
    dislocation: yup
      .string()
      .max(255, t("maximum", { num: 255 }))
      .required(t("Required")),

    ipAddress: yup
      .string()
      .matches(regex, t("Invalid IP address format"))
      .required(t("Required")),
    rules: yup.array().min(1),
  });

export const validationSchemaPinger = (t) =>
  yup.object({
    ip: yup
      .string()
      .matches(regex, t("Invalid IP address format"))
      .required(t("Required")),
    rules: yup.array().min(1),
  });
export const validationSchemaCMD = (t) =>
  yup.object({
    to: yup
      .string()
      .matches(regex, t("Invalid IP address format"))
      .required(t("Required")),
    cmd: yup.string().required("Select an option"),
  });
