import { createSlice } from "@reduxjs/toolkit";
import { moderator } from "../../utils/functions";

const initialState = {
  focused: false,
  isEditing: false,
  dialog: false,
  isAdmin: false,
  isNtlm: false,
  selected: null,
  NTLMRows: [],
  adminRows: [],
  moderatorRows: [],
  bossRows: [],
  operatorRows: [],
  egineerRows: [],
  workerRows: [],
  value: moderator() ? "2" : "1",
  initialValues: false,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setFocused: (state, action) => {
      state.focused = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setStaffDialog: (state, action) => {
      state.dialog = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setIsNtlm: (state, action) => {
      state.isNtlm = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setNTLMRows: (state, action) => {
      state.NTLMRows = action.payload;
    },
    setAdminRows: (state, action) => {
      state.adminRows = action.payload;
    },
    setModeratorRows: (state, action) => {
      state.moderatorRows = action.payload;
    },
    setBossRows: (state, action) => {
      state.bossRows = action.payload;
    },
    setOperatorRows: (state, action) => {
      state.operatorRows = action.payload;
    },
    setEgineerRows: (state, action) => {
      state.egineerRows = action.payload;
    },
    setWorkerRows: (state, action) => {
      state.workerRows = action.payload;
    },
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setInitialValues: (state, action) => {
      state.initialValues = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFocused,
  setIsEditing,
  setStaffDialog,
  setIsAdmin,
  setIsNtlm,
  setSelected,
  setNTLMRows,
  setAdminRows,
  setModeratorRows,
  setBossRows,
  setOperatorRows,
  setEgineerRows,
  setWorkerRows,
  setValue,
  setInitialValues,
} = staffSlice.actions;

export default staffSlice.reducer;
