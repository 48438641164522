/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { MapContainer } from "react-leaflet";
import styled from "@emotion/styled";
import initMap from "./pure/map";
import useTheme from "./../../hooks/useTheme";

const Map = styled(MapContainer)`
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const zooom = 18;
const center = [41.13822189091985, 63.94042968750001];

function BasicMap({
  resize1,
  resize2,
  pos,
  zoom,
  createMarker,
  setPos,
  objects,
}) {
  const [map, setMap] = useState();
  const [lfMap, setLFMap] = useState(null);
  const { theme } = useTheme();

  useEffect(() => {
    if (!lfMap) return;
    if (createMarker) lfMap.ctrlCreateMarkerOnEvent(setPos);
    else lfMap.ctrlCreateMarkerOffEvent();
  }, [createMarker, lfMap]);

  useEffect(() => {
    if (!map) return;
    setTimeout(() => {
      map.invalidateSize();
    }, 300);
  }, [resize1, resize2]);

  /* INIT MAP */
  useEffect(() => {
    if (map && !lfMap) {
      setLFMap(initMap(map));
    }
  }, [map]);

  useEffect(() => {
    if (!pos || !lfMap) return;
    lfMap.addMarker(pos);
  }, [pos, lfMap]);

  useEffect(() => {
    if (!objects || !lfMap) return;
    lfMap.addMarkers(objects);
  }, [objects, lfMap]);

  useEffect(() => {
    if (!map || !lfMap) return;
    setTimeout(() => map.invalidateSize(), 300);
    if (theme === "DARK") {
      lfMap.osm.removeFrom(map);
      lfMap.dark.addTo(map);
    }
    if (theme === "GREEN") {
      lfMap.dark.removeFrom(map);
      lfMap.osm.addTo(map);
    }
  }, [theme, lfMap, map]);

  return (
    <>
      <Map
        zoom={zoom ? zoom : pos ? zooom : 5}
        center={pos ? pos : center}
        whenReady={(e) => setMap(e.target)}
      />
    </>
  );
}
export default BasicMap;
