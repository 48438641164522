import axiosInstance from "../../utils/axios";

const getNotification = (page, limit, user) =>
  axiosInstance.get(
    `/config/notification?limit=${limit ? limit : 50}&page=${page}&private=${
      user ? true : false
    }`
  );
const getUserById = (id) => axiosInstance.get(`/config/notification/${id}`);
const setIsRead = (id) =>
  axiosInstance.post(`/config/notification/read`, { id: id });
const setIsReadAll = (id) =>
  axiosInstance.post(`/config/notification/read-all`, {});
const getCameraById = (id) => axiosInstance.get(`/config/cameras/${id}`);
const getNotificationByDate = (start, end, page, user) =>
  axiosInstance.get(
    `/config//notification?limit=50&page=${page}&private=${
      user ? true : false
    }&startDate=${start}&finishDate=${end}`
  );
const notificationApi = {
  getNotificationByDate,
  getNotification,
  getUserById,
  getCameraById,
  setIsRead,
  setIsReadAll,
};

export default notificationApi;
