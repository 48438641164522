import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const treeSlice = createSlice({
  name: "tree",
  initialState,
  reducers: {
    setTree(state, action) {
      return action.payload;
    },
  },
});

export const { setTree } = treeSlice.actions;
export default treeSlice.reducer;
