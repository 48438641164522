import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  points: 0,
  rank: 0,
};

export const pointsSlice = createSlice({
  name: "points",
  initialState,
  reducers: {
    setPoint: (state, action) => {
      state.points = action.payload;
    },
    setRank: (state, action) => {
      state.rank = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPoint, setRank } = pointsSlice.actions;

export default pointsSlice.reducer;
