import * as React from "react";
import UsersInfo from "./UserDialog";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CameraInfo from "./CameraDialog";
import CloseIcon from "@mui/icons-material/Close";
import notificationApi from "../api";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function MainDialog({ open, setOpen, id }) {
  const { t } = useTranslation();
  const [usersData, setUsersData] = React.useState({
    loading: false,
    data: null,
  });
  const { state } = useLocation();
  const fetchUsersData = async () => {
    setUsersData({ ...usersData, loading: true });
    try {
      const { data } = await notificationApi.getUserById(id);
      state !== null && (await notificationApi.setIsRead(id));

      setUsersData({
        data: data.data,
        loading: false,
      });
    } catch (err) {
      setUsersData({ data: [], loading: false });
    }
  };
  const handleClose = () => {
    //"!filter ? fetchNotificationData() : fetchNotificationByDate();"
    setOpen(false);
  };
  React.useEffect(() => {
    fetchUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1300,
    bgcolor: "background.paper",
    // height: 730,
    border: "1px solid #000",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    paddingTop: 1,
    paddingRight: 2,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 700,
        }}
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ mb: 3 }}
          >
            <Typography variant="h4" align="center">
              {t("notifications")}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon color="error" />
            </IconButton>
          </Stack>
          <Grid container sx={{ bgcolor: "#1B2635" }} spacing={2}>
            {usersData?.loading ? (
              <CircularProgress
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                color="inherit"
              />
            ) : (
              <>
                <Grid item md={12}>
                  <Paper
                    sx={{
                      width: "99.3%",
                      p: 2,
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      align={"center"}
                      style={{ cursor: "pointer" }}
                      dangerouslySetInnerHTML={{
                        __html: usersData?.data?.content,
                      }}
                      onClick={() => {}}
                    />
                  </Paper>
                </Grid>
                <Grid item md={4}>
                  <UsersInfo data={usersData?.data?.user} />
                </Grid>
                <Grid item md={8}>
                  <CameraInfo data={usersData?.data?.camera} />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
