import axiosInstance from "../../utils/axios";

const getPingData = (ip) => axiosInstance.post(`/cmd/ping`, ip);
const postCMD = (data) => axiosInstance.post(`/cmd/push`, data);

const navbarApi = {
  getPingData,
  postCMD,
};
export default navbarApi;
