import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import { Box, Divider, Paper, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import WorkIcon from "@mui/icons-material/Work";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useTranslation } from "react-i18next";
export default function UsersInfo({ data }) {
  const { t } = useTranslation();

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          height: "450px",
          marginBottom: 2,
        }}
      >
        <Paper
          sx={{
            pt: 0,
            width: "100%",
            height: "60px",
            bgcolor: "background.body",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" fontWeight={900} p={2} pt={4} align="center">
            {t("User info")}
          </Typography>
        </Paper>
        <Divider />

        <List sx={{ pt: 0, width: "100%", height: "255px" }}>
          <ListItem disableGutters key={data?.id}>
            <ListItemButton
            // sx={{ borderLeft: `4px solid ${blue[300]}` }}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText secondary={t("Name")} primary={data?.full_name} />
            </ListItemButton>
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItemButton
          // sx={{ borderLeft: `4px solid ${blue[300]}`, mb: 1 }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={data?.group?.title}
              secondary={t("Entrepreneur")}
            />
          </ListItemButton>
          <Divider variant="inset" component="li" />
          <ListItemButton
          // sx={{ borderLeft: `4px solid ${blue[300]}`, my: 2 }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <DescriptionIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={data?.description}
              secondary={t("Description")}
            />
          </ListItemButton>
          <Divider variant="inset" component="li" />
          <ListItemButton
          // sx={{ borderLeft: `4px solid ${blue[300]}`, my: 2 }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <LocalPhoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={data?.phone_number}
              secondary={t("Phone number")}
            />
          </ListItemButton>
          <Divider variant="inset" component="li" />
        </List>
        <Box
          sx={{
            mb: 0,
            width: "100%",
            height: "40px",
            position: "absolute",
            bottom: "0",

            // transform: "translate(-50%, -0%);",
          }}
        ></Box>
      </Paper>
    </div>
  );
}
