import React, { useEffect, useState } from "react";

const TextGenerator = ({ text, depend }) => {
  const [generatedText, setGeneratedText] = useState("");

  useEffect(() => {
    let i = 0;
    setGeneratedText("");
    const interval = setInterval(() => {
      if (i < text.length) {
        setGeneratedText((prevState) => prevState + text.charAt(i));
        i++;
      } else {
        clearInterval(interval);
      }
    }, 20);
    return () => clearInterval(interval);
  }, [text, depend.loading]);

  return <div>{generatedText}</div>;
};

export default TextGenerator;
