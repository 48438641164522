import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { Box, CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import AppVersion from "../components/auth/AppVersion";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
      <Box sx={{ position: "absolute", bottom: "0", right: "0" }}>
        {" "}
        <AppVersion />
      </Box>
    </Root>
  );
};

export default Auth;
