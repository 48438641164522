import React from "react";
import styled from "@emotion/styled";
import { Grid, Avatar, Typography } from "@mui/material";
import { isValidToken } from "../../utils/jwt";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 258px;
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 0px;
`;

const SidebarFooter = ({ ...rest }) => {
  const accessToken = window.localStorage.getItem("token");
  const user = isValidToken(accessToken);

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item sx={{ display: "inline" }}>
          <Avatar alt={user?.user?.full_name} src={user?.user?.full_name} />
        </Grid>
        <Grid item>
          <FooterText variant="body2">{user?.user?.full_name}</FooterText>
          <FooterSubText variant="caption">
            {user?.user?.description}
          </FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
