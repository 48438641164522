import L from "leaflet";

/* Leaflet Modules */
import "./leaflet/leaflet.zoomlabel";
import "./leaflet/leaflet.awesome-markers";

/* Leaflet CSS */
import "leaflet/dist/leaflet.css";
import "./styles/leaflet/leaflet.css";
import "./styles/leaflet/leaflet.ruler.css";
import "./styles/leaflet/leaflet.zoomlabel.css";
import "./styles/others/flaticon.css";
import "./styles/leaflet/leaflet.search.css";
import "./styles/leaflet/leaflet.buttons.css";
import "./styles/leaflet/leaflet.contextmenu.css";
import "./styles/others/fontawesome.css";
import "./styles/leaflet/leaflet.awesome-markers.css";
import "./styles/leaflet/leaflet.loader.css";
import "./styles/leaflet/leaflet.markercluster.css";
import "./styles/leaflet/leaflet.markercluster.default.css";
import "./styles/leaflet/leaflet.fullscreen.css";
import { isIpAddressOrDomain } from "../../../utils/functions";

const initMap = (map) => {
  const attr = {
    maxZoom: 18,
    attribution: `&copy; Smart-Base 2014-${new Date().getFullYear()}`,
  };
  const osm = L.tileLayer(
    `${isIpAddressOrDomain()}/map/light/{s}/{x}/{y}/{z}`,
    L.Util.extend({}, attr)
  );
  const dark = L.tileLayer(
    `${isIpAddressOrDomain()}/map/dark/{s}/{x}/{y}/{z}`,
    L.Util.extend({}, attr)
  ).addTo(map);
  const google = L.tileLayer(
    `${isIpAddressOrDomain()}/map/sat/{s}/{x}/{y}/{z}`,
    L.Util.extend({}, attr, { subdomains: "0123" })
  );
  const baseMaps = {
    Dark: dark,
    OSM: osm,
    Карта: google,
  };
  L.control.zoomLabel().setPosition("bottomright").addTo(map);
  L.control.scale({ imperial: false }).setPosition("bottomright").addTo(map);
  L.control.layers(baseMaps).addTo(map);

  const icon = L.AwesomeMarkers.icon({
    prefix: "fa",
    icon: "fa-video",
    markerColor: "#407ad6",
  });

  const objectIcon = L.AwesomeMarkers.icon({
    icon: "star",
    markerColor: "#407ad6",
  });

  const fg = L.featureGroup();
  fg.addTo(map);

  const addMarker = (coords) => {
    fg.clearLayers();
    L.marker(coords, { icon }).addTo(fg);
  };

  const addMarkers = (coordsArr) => {
    fg.clearLayers();
    const bounds = L.latLngBounds();
    const popupContent = (obj) => {
      return `<b>${obj.title}</b><br>${obj.args.address}`;
    };
    coordsArr.forEach((obj) => {
      const latlng = [obj.args.location.lat, obj.args.location.lng];
      bounds.extend(latlng);
      L.marker(latlng, {
        icon: objectIcon,
      })
        .bindPopup(popupContent(obj))
        .addTo(fg);
      // console.log(coordsArr);
      map.flyToBounds(bounds, { padding: [30, 30] });
    });
  };

  const createMarker = (e, setCoords) => {
    if (e.originalEvent.ctrlKey) setCoords([e.latlng.lat, e.latlng.lng]);
  };
  const ctrlCreateMarkerOnEvent = (setCoords) => {
    L.DomEvent.on(map, "click", (e) => createMarker(e, setCoords), this);
  };

  const ctrlCreateMarkerOffEvent = () => {
    L.DomEvent.off(map, "click", createMarker);
  };

  return {
    ctrlCreateMarkerOnEvent,
    ctrlCreateMarkerOffEvent,
    addMarker,
    addMarkers,
    osm,
    dark,
    google,
  };
};

export default initMap;
