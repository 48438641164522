import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import pointsReducer from "./slices/points";
import staffReducer from "./slices/staff";
import treeReducer from "./slices/tree";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    points: pointsReducer,
    staff: staffReducer,
    tree: treeReducer,
  },
});
