import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import useTheme from "../../hooks/useTheme";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import VersionTable from "../../pages/settings/appmanager/version/components/VersionTable";

export default function AppVersion() {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const handleClickOpen = () => {
    setScroll("paper");
    setOpen(true);
  };
  const { theme } = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              // height: "230px",

              p: 1,

              overflowY: "auto",
              overflowX: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "3px",
                height: "3px",
              },
              "&::-webkit-scrollbar-track": {
                background: theme === "DARK" ? "#233044" : "#F7F9FC",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
              "& ::-webkit-scrollbar-thumb:hover": {},
            }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <Box sx={{}}>
      <Button onClick={handleClickOpen}>Appmanager</Button>
      <Dialog
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="scroll-dialog-title">
          <IconButton
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",

              right: "5px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="LochinKozLite" {...a11yProps(0)} />
            <Tab label="fsasync" {...a11yProps(1)} />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <AppBar sx={{ position: "relative", m: "0" }}>
              <Toolbar>
                <Box sx={{ width: "100vh" }}>
                  <TabPanel value={value} index={0}>
                    <VersionTable name={"LochinKozLite"} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <VersionTable name={"fsasync"} />
                  </TabPanel>
                </Box>
              </Toolbar>
            </AppBar>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
