import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, User } from "react-feather";
import { useTranslation } from "react-i18next";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MainDialog from "../notification/Dialogs/MainDialog";
import notificationApi from "../notification/api";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({
  title,
  description,
  Icon,
  setId,
  setRowId,
  row,
  setOpenNotificationDialog,
  readList,
}) {
  return (
    <ListItem
      divider
      selected={!readList.includes(row.id)}
      component={Link}
      sx={{ py: 0 }}
      dense
      to="#"
      onClick={() => {
        setId(row.id);
        readList.push(row.id);
        setRowId(row?.id);
        setOpenNotificationDialog(true);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ width: 32, height: 32 }}>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

let readList = [];
function NavbarNotificationsDropdown({
  setId,
  count,
  notificationData,
  fetchNotificationDataCount,

  // readList
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [notificationRow, setNotificationRow] = useState([]);
  const handleOpen = () => {
    fetchNotificationDataCount();
    readList = [];
    setOpen(true);
  };
  const fetchReadAll = async () => {
    try {
      await notificationApi.setIsReadAll();
      fetchNotificationDataCount();
    } catch (err) {}
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const createNotificationRows = (data) => {
      if (data !== null) {
        return setNotificationRow(data);
      }
    };
    createNotificationRows(notificationData);
  }, [notificationData]);

  return (
    <React.Fragment>
      <Tooltip title={t("notifications")}>
        <IconButton
          // component={Link}
          // to="/notification"
          color="inherit"
          ref={ref}
          onClick={handleOpen}
          size="large"
        >
          {count > 0 ? (
            <Badge color="secondary" badgeContent={count}>
              <Bell />
            </Badge>
          ) : (
            <Bell />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" component="div" color="textPrimary">
            {count > 0
              ? ` ${t("New notifications")} - ${count}`
              : t("No new notifications")}
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notificationRow?.map(
              (row, index) =>
                !row.is_read &&
                index < 11 && (
                  <Notification
                    setNotificationRow={setNotificationRow}
                    notificationRow={notificationRow}
                    readList={readList}
                    setId={setId}
                    key={index}
                    title={row?.full_name}
                    description={row?.title}
                    Icon={User}
                    setOpenNotificationDialog={setOpenNotificationDialog}
                    setRowId={setRowId}
                    row={row}
                  />
                )
            )}
          </List>
          <Box
            p={1}
            display="flex"
            justifyContent={"space-between"}
            sx={{ px: 3 }}
          >
            <Button
              variant="outlined"
              size="small"
              component={NavLink}
              state={{ rowId: rowId, user: true }}
              to="/personalNotification"
              onClick={handleClose}
              startIcon={<VisibilityIcon />}
            >
              {t("Show all")}
            </Button>
            {/* {count > 0 && ( */}
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={fetchReadAll}
              startIcon={<DoneAllIcon />}
            >
              {t("Read all")}
            </Button>
            {/* )} */}
          </Box>
        </React.Fragment>
      </Popover>

      {openNotificationDialog && (
        <MainDialog
          open={openNotificationDialog}
          setOpen={setOpenNotificationDialog}
          id={rowId}
        />
      )}
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
