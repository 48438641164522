import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { QRCodeSVG } from "qrcode.react";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useTranslation } from "react-i18next";

function NavbarQR({ tlgrm }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Tooltip title={"QR"}>
        <IconButton color="inherit" size="large" onClick={handleClickOpen}>
          <QrCodeIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <QRCodeSVG
            value={`https://t.me/lochinkoz_bot?start=${tlgrm}`}
            size={250}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
          />
          <Button
            startIcon={<TelegramIcon />}
            variant="contained"
            component={Link}
            href={`https://t.me/lochinkoz_bot?start=${tlgrm}`}
            target={"_blank"}
          >
            {t("Open in telegram")}
          </Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default NavbarQR;
