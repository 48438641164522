import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

function NavbarRank() {
  const rank = useSelector((state) => state.points.rank);
  // const rank = 3;

  return (
    <Tooltip title={`${rank}`}>
      <IconButton variant="text" color="inherit">
        <WorkspacePremiumIcon
          sx={{
            color:
              rank === 1
                ? "#FFA726"
                : rank === 2
                ? "#64748B"
                : rank === 3 && "#E3AF66",
          }}
          fontSize="large"
        />
      </IconButton>
    </Tooltip>
  );
}
export default NavbarRank;
