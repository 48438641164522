import axios from "axios";
import { isIpAddressOrDomain } from "./functions";

const axiosInstance = axios.create({
  // baseURL: "https://api.lochinkoz.uz",
  // baseURL: "http://192.168.5.55:37780",
  baseURL: `${isIpAddressOrDomain()}`,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.code === 401
    ) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(
      (error?.response?.data ? error?.response?.data : error?.response) ||
        "Something went wrong"
    );
  }
);

export default axiosInstance;
