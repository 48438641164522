import * as React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  // Menu,
  // MenuItem,
  IconButton as MuiIconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function DialogExit({ open, handleClose, handleSignOut }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center" }}>{t("Log Out")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Do you really want to log out")}?
        </DialogContentText>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        pb={3}
      >
        <Button
          autoFocus
          onClick={handleSignOut}
          color="primary"
          variant="contained"
        >
          {t("Yes")}
        </Button>
        <Button onClick={handleClose} color="error" variant="contained">
          {t("No")}
        </Button>
      </Stack>
    </Dialog>
  );
}

function NavbarUserDropdown() {
  const { t } = useTranslation();
  const [anchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const toggleMenu = (event) => {
  //   setAnchorMenu(event.currentTarget);
  // };

  // const closeMenu = () => {
  //   setAnchorMenu(null);
  // };

  const handleSignOut = async () => {
    await signOut();
    navigate("/sign-in");
  };

  return (
    <React.Fragment>
      <DialogExit
        handleClose={handleClose}
        handleSignOut={handleSignOut}
        open={open}
      />
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleClickOpen}
        color="inherit"
        size="large"
      >
        <Tooltip title={t("Log Out")}>
          <Power />
        </Tooltip>
      </IconButton>
      {/* <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      > */}
      {/* <MenuItem onClick={closeMenu}>{t("profile")}</MenuItem> */}
      {/* <MenuItem onClick={handleClickOpen}>{t("sign out")}</MenuItem> */}
      {/* </Menu> */}
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
