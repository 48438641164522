import { useContext } from "react";

import { AuthContext } from "../contexts/JWTContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
