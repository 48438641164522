import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import ru from "./translations/ru";
import uzCr from "./translations/uz-cr";
import uzLtn from "./translations/uz-ltn";

const resources = {
  uz: {
    translation: uzLtn,
  },
  cr: {
    translation: uzCr,
  },
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

const lang = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
  resources,
  // lng: getLang(),
  fallbackLng: lang ? lang : "cr",
  interpolation: {
    escapeValue: false,
  },
});
