import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page404() {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="div" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="div" variant="h5" align="center" gutterBottom>
        {t("Page not found.")}
      </Typography>
      <Typography component="div" variant="body1" align="center" gutterBottom>
        {t("The page you are looking for might have been removed.")}
      </Typography>

      {user ? (
        <Button
          component={Link}
          to={
            user?.user?.group_id === -1
              ? "/verification"
              : user?.user?.group_id === 0
              ? "/dashboard"
              : user?.user?.group_id > 0 && "/map"
          }
          variant="contained"
          color="secondary"
          mt={2}
        >
          {t("Return to website")}
        </Button>
      ) : (
        <Button
          component={Link}
          to="/sign-in"
          variant="contained"
          color="secondary"
          mt={2}
        >
          {t("Return to website")}
        </Button>
      )}
    </Wrapper>
  );
}

export default Page404;
