import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import TextGenerator from "../TextGenerator";
import { validationSchemaCMD } from "../../utils/validations";
import navbarApi from "./api";

export function CommandsForAdmin() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    loading: false,
    data: null,
    sts: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setData({
      loading: false,
      data: null,
      sts: null,
    });
    setOpen(false);
  };

  const initialValues = { to: "", cmd: "" };

  const handleCMD = async (data) => {
    setData({ ...data, loading: true });
    try {
      await navbarApi.postCMD(data);
      setData({ data: "Message: Success", loading: false, sts: true });
    } catch (err) {
      setData({ data: err?.message?.en, loading: false, sts: false });
    }
  };

  const commands = ["NEW", "CFG", "UPD", "BKL", "RST", "IMG", "SNP", "ERR"];

  return (
    <>
      <Tooltip title={"CMD"}>
        <IconButton color="inherit" size="large" onClick={handleClickOpen}>
          <KeyboardCommandKeyIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} maxWidth={"100"}>
        <Box p={1}>
          <Typography sx={{ textAlign: "center", pt: 1 }} variant={"h6"}>
            CMD
          </Typography>
        </Box>
        <IconButton
          size="small"
          sx={{ position: "absolute", right: "5px", top: "5px" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchemaCMD(t)}
            onSubmit={(values) => {
              handleCMD(values);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  spacing={2}
                >
                  <TextField
                    sx={{ width: 180 }}
                    name="to"
                    size="small"
                    onChange={props.handleChange}
                    value={props.values.to}
                    id="ip"
                    label={t("IP")}
                    type="text"
                    fullWidth
                    variant="outlined"
                    error={props.touched.to && Boolean(props.errors.to)}
                    // helperText={props.touched.ip && props.errors.ip}
                  />
                  <FormControl
                    sx={{ width: 180 }}
                    variant="outlined"
                    size="small"
                    error={props.touched.cmd && Boolean(props.errors.cmd)}
                  >
                    <InputLabel sx={{ width: 180 }} htmlFor="cmd">
                      {t("Commands")}
                    </InputLabel>
                    <Select
                      sx={{ width: 180 }}
                      name="cmd"
                      value={props.values.cmd}
                      onChange={props.handleChange}
                      label={t("Commands")}
                      error={props.touched.cmd && Boolean(props.errors.cmd)}
                      inputProps={{
                        id: "CMD",
                      }}
                    >
                      {commands.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <LoadingButton
                    loading={data.loading}
                    variant="contained"
                    type="submit"
                  >
                    {t("Send")}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
          <Box
            mt={2}
            sx={{ display: "flex", alignItems: "center", gap: "7px" }}
          >
            {data.data &&
              !data.loading &&
              (data.sts ? (
                <CheckCircleIcon color="primary" />
              ) : (
                <ErrorIcon color="error" />
              ))}
            {
              <TextGenerator
                text={(!data.loading && data.data) || ""}
                depend={data}
              />
            }
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
