import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { validationSchemaPinger } from "../../utils/validations";
import navbarApi from "./api";
import { LoadingButton } from "@mui/lab";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextGenerator from "../TextGenerator";
import { ReactComponent as IPIcon } from "../../vendor/ipIcon.svg";

function IPPinger() {
  const theme = JSON.parse(localStorage.getItem("theme"));
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    loading: false,
    data: null,
    sts: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setData({
      loading: false,
      data: null,
      sts: null,
    });
    setOpen(false);
  };

  const initialValues = { ip: "" };

  const handlePing = async (ip) => {
    setData({ ...data, loading: true });
    try {
      await navbarApi.getPingData(ip);
      setData({ data: "Message: Active", loading: false, sts: true });
    } catch (err) {
      setData({ data: err?.message?.en, loading: false, sts: false });
    }
  };

  return (
    <>
      <Tooltip title={"Pinger"}>
        <IconButton color="inherit" size="large" onClick={handleClickOpen}>
          <IPIcon
            style={{
              fill: theme === "DARK" ? "#E0E0E0" : "#B0B0B0",
              stroke: "transparent",
            }}
          />
        </IconButton>
      </Tooltip>
      <Dialog open={open} maxWidth={"100"}>
        <Box p={1}>
          <Typography sx={{ textAlign: "center", pt: 1 }} variant={"h6"}>
            Pinger
          </Typography>
        </Box>
        <IconButton
          size="small"
          sx={{ position: "absolute", right: "5px", top: "5px" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchemaPinger(t)}
            onSubmit={(values) => {
              handlePing(values);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  spacing={2}
                >
                  <TextField
                    name="ip"
                    size="small"
                    onChange={props.handleChange}
                    value={props.values.ip}
                    id="ip"
                    label={t("IP")}
                    type="text"
                    fullWidth
                    variant="outlined"
                    error={props.touched.ip && Boolean(props.errors.ip)}
                    // helperText={props.touched.ip && props.errors.ip}
                  />
                  <LoadingButton
                    loading={data.loading}
                    variant="contained"
                    type="submit"
                  >
                    {t("Send")}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
          <Box
            mt={2}
            sx={{ display: "flex", alignItems: "center", gap: "7px" }}
          >
            {data.data &&
              !data.loading &&
              (data.sts ? (
                <CheckCircleIcon color="primary" />
              ) : (
                <ErrorIcon color="error" />
              ))}
            {
              <TextGenerator
                text={(!data.loading && data.data) || ""}
                depend={data}
              />
            }
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default IPPinger;
