import {
  Sliders,
  Search,
  CheckSquare,
  Settings,
  Users,
  Book,
  Grid,
  Briefcase,
  Compass,
} from "react-feather";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
// import PodcastsIcon from "@mui/icons-material/Podcasts";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CalculateIcon from "@mui/icons-material/Calculate";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import SecurityIcon from "@mui/icons-material/Security";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LaptopIcon from "@mui/icons-material/Laptop";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

export const admin = [
  {
    icon: Grid,
    title: "Dashboard",
    children: [
      { href: "/dashboard", icon: LeaderboardIcon, title: "Violations" },
      { href: "/operators-stat", icon: CalculateIcon, title: "Operators" },
      { href: "/devices", icon: LaptopIcon, title: "Devices" },
      { href: "/devices-stat", icon: DeviceHubIcon, title: "Devices (V2)" },
      { href: "/monitoring", icon: MonitorHeartIcon, title: "Monitoring" },
      {
        href: "/snapshots",
        icon: PhotoSizeSelectActualIcon,
        title: "Snapshots",
      },
    ],
  },

  {
    icon: AssessmentIcon,
    title: "Report",
    children: [
      {
        href: "/report-by-violations",
        icon: ListAltIcon,
        title: "Violations",
      },
      {
        href: "/report-disabled-devices",
        icon: VideocamOffIcon,
        title: "Disabled devices",
      },
      {
        href: "/report-by-operator",
        icon: AccountBoxIcon,
        title: "Operator",
      },
      {
        href: "/report-by-camera",
        icon: VideocamIcon,
        title: "Report by camera (Violations)",
      },
    ],
  },

  { href: "/search", icon: Search, title: "Search" },
  { href: "/verification", icon: CheckSquare, title: "Verification" },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    children: [
      { href: "/staff", icon: Users, title: "Staff" },
      { href: "/settings", icon: Briefcase, title: "Entrepreneurs" },
      {
        href: "/manage-operators",
        icon: ManageAccountsIcon,
        title: "Manage Operators",
      },
      { href: "/directory", icon: Book, title: "Directory" },
      { href: "/appmanager", icon: CloudCircleIcon, title: "Appmanager" },
      { href: "/filemanager", icon: UploadFileIcon, title: "FileManager" },
      { href: "/maintain", icon: DataUsageIcon, title: "Maintain" },
      {
        href: "/notification",
        icon: NotificationsNoneIcon,
        title: "notifications",
      },

      { href: "/controller-users", icon: LocalPoliceIcon, title: "YPX users" },
    ],
  },
  {
    href: "/control",
    icon: SecurityIcon,
    title: "YPX",
    children: [
      {
        href: "/control",
        icon: Sliders,
        title: "Control",
      },
      { href: "/search-ypx", icon: Search, title: "Search" },
    ],
  },
  { href: "/map", icon: Compass, title: "Map" },
];

export const operator = [
  { href: "/verification", icon: CheckSquare, title: "Verification" },
];

export const ypx = (verif, access, search, report) => {
  const pages = [];
  access && pages.push({ href: "/control", icon: Sliders, title: "Control" });
  verif &&
    pages.push({
      href: "/verification",
      icon: CheckSquare,
      title: "Verification",
    });
  search && pages.push({ href: "/search-ypx", icon: Search, title: "Search" });
  report &&
    pages.push({
      href: "/report-by-violations",
      icon: AssessmentIcon,
      title: "Report",
    });
  return pages;
};

export const moderator = [
  { href: "/dashboard", icon: LeaderboardIcon, title: "Violations" },
  { href: "/search", icon: Search, title: "Search" },
  {
    href: "/operators-stat",
    icon: CalculateIcon,
    title: "Operators statistics",
  },
  { href: "/staff", icon: Users, title: "Operators" },
  {
    href: "/manage-operators",
    icon: ManageAccountsIcon,
    title: "Manage Operators",
  },
  { href: "/verification", icon: CheckSquare, title: "Verification" },
];

export const boss = [
  {
    icon: Grid,
    title: "Dashboard",
    children: [
      { href: "/dashboard", icon: LeaderboardIcon, title: "Violations" },
      { href: "/operators-stat", icon: CalculateIcon, title: "Operators" },
      { href: "/devices", icon: LaptopIcon, title: "Devices" },
      { href: "/devices-stat", icon: DeviceHubIcon, title: "Devices (V2)" },
      { href: "/monitoring", icon: MonitorHeartIcon, title: "Monitoring" },
      {
        href: "/snapshots",
        icon: PhotoSizeSelectActualIcon,
        title: "Snapshots",
      },
    ],
  },
  { href: "/report-by-violations", icon: AssessmentIcon, title: "Report" },
  {
    href: "/report-disabled-devices",
    icon: VideocamOffIcon,
    title: "Disabled devices",
  },
  {
    href: "/report-by-camera",
    icon: VideocamIcon,
    title: "Report by camera (Violations)",
  },
  { href: "/search", icon: Search, title: "Search" },
  { href: "/map", icon: Compass, title: "Map" },
];

export const engineer = [
  { href: "/devices", icon: LaptopIcon, title: "Devices" },
  { href: "/report-by-violations", icon: AssessmentIcon, title: "Report" },
  {
    href: "/report-disabled-devices",
    icon: VideocamOffIcon,
    title: "Disabled devices",
  },
  {
    href: "/report-by-camera",
    icon: VideocamIcon,
    title: "Report by camera (Violations)",
  },
  {
    href: "/snapshots",
    icon: PhotoSizeSelectActualIcon,
    title: "Snapshots",
  },
  { href: "/operators-stat", icon: CalculateIcon, title: "Operators" },
  { href: "/devices-stat", icon: DeviceHubIcon, title: "Devices (V2)" },
  { href: "/monitoring", icon: MonitorHeartIcon, title: "Monitoring" },
  { href: "/search", icon: Search, title: "Search" },
  { href: "/settings", icon: Briefcase, title: "Entrepreneurs" },
  { href: "/map", icon: Compass, title: "Map" },
];
export const worker = [];

export const tadbirkor = [
  { href: "/search", icon: Search, title: "Search" },
  { href: "/devices", icon: LaptopIcon, title: "Devices" },
  { href: "/report-by-violations", icon: AssessmentIcon, title: "Report" },
  { href: "/monitoring", icon: MonitorHeartIcon, title: "Monitoring" },
  {
    href: "/snapshots",
    icon: PhotoSizeSelectActualIcon,
    title: "Snapshots",
  },
];
