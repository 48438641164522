import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, Container, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {
  admin,
  boss,
  engineer,
  moderator,
  operator,
  tadbirkor,
  worker,
  ypx,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
`;
const Scroll = styled.div`
  height: calc(100vh - 120px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #555;
  }
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, map }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [role, setRole] = useState(admin);

  const info = JSON.parse(localStorage.getItem("user"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const [isYpx] = useState(
    ypx(
      user?.user?.verify,
      user?.user?.access,
      user?.user?.search,
      user?.user?.report
    )
  );

  const getUserType = () => {
    if (!user?.user) return;
    if (user?.user?.group_id === -6) return worker;
    if (user?.user?.group_id === -5) return engineer;
    if (user?.user?.group_id === -4) return boss;
    if (user?.user?.group_id === -3) return moderator;
    if (user?.user?.group_id === -2) return isYpx;
    if (user?.user?.group_id === -1) return operator;
    if (user?.user?.group_id === 0) return admin;
    if (user?.user?.group_id > 0) return tadbirkor;
    else return;
  };

  useEffect(() => {
    setRole(getUserType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  if (!info) return 123;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {info?.user?.group_id === -1 || info?.user?.group_id === -6 ? (
        ""
      ) : (
        <Drawer>
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={[
                {
                  title: "",
                  pages: role,
                },
              ]}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={[
                {
                  title: "",
                  pages: role,
                },
              ]}
            />
          </Box>
        </Drawer>
      )}

      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <Scroll>
          <MainContent
            py={map ? 0 : isLgUp ? 2 : 2}
            px={map ? 0 : isLgUp ? 3 : 2}
          >
            <Container maxWidth="xl">
              {children}
              <Outlet />
            </Container>
          </MainContent>
        </Scroll>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
