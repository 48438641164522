import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import useTheme from "./../../hooks/useTheme";
import { Sun, Moon } from "react-feather";
import { useTranslation } from "react-i18next";

function NavbarThemeChange() {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  return (
    <React.Fragment>
      <Tooltip
        title={
          theme === "GREEN" ? t("night") : theme === "DARK" ? t("day") : null
        }
      >
        <IconButton
          color="inherit"
          size="large"
          onClick={() =>
            theme === "GREEN"
              ? setTheme("DARK")
              : theme === "DARK"
              ? setTheme("GREEN")
              : null
          }
        >
          {theme === "GREEN" ? <Moon /> : theme === "DARK" ? <Sun /> : null}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarThemeChange;
